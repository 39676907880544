import React from 'react'
import "./hrtag.css"
import hrtag from "../../assets/8.png"

export default function Hrtag() {
  return (
    <>
    <img src={hrtag} alt="hrtag" className="footerhr"/>
    </>
    )
}
